<template>
  <v-container>
    <v-row>
      <v-col class="d-block d-md-flex justify-center">
        <v-card
          elevation="16"
          :rounded="$vuetify.breakpoint.mdAndUp ? 'xl' : 'lg'"
          :width="$vuetify.breakpoint.mdAndUp ? 750 : '100%'"
        >
          <v-toolbar
            color="secondary"
            dark
            flat
            height="90"
          >
            <v-icon
              left
              color="white"
            >
              {{ icons.mdiCog }}
            </v-icon>
            <v-toolbar-title class="white--text">
              Ayarlar
            </v-toolbar-title>

            <template v-slot:extension>
              <v-tabs
                v-model="tab"
              >
                <v-tabs-slider color="white"></v-tabs-slider>
                <v-tab
                  v-for="item in items"
                  :key="item"
                  active-class="secondary-light"
                >
                  <div class="text-capitalize white--text">
                    {{ item }}
                  </div>
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <div class="px-0 px-md-3 py-9">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.unvan"
                          label="Firma Adı"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="settings.adres"
                          label="Adres"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="settings.country"
                          label="Ülke"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="settings.city"
                          label="İl"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="settings.state"
                          label="İlçe"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.email"
                          label="E-Posta"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.web"
                          label="Web"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="settings.telefon"
                          label="Telefon"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="settings.fax"
                          label="Faks"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="settings.gsm"
                          label="Mobil"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings._siteAdresi"
                          label="Site URL"

                          hint="Örnek: maverabilisim.com"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings._siteAdi"
                          label="Site Genel Adı"
                          hint="Örnek: Mavera Bilişim AŞ"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.sozlesmedekiLakap"
                          label="Sözleşmelerdeki Lakap"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.recaptcha_site_key"
                          label="Google Recaptcha Kodu"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.recaptcha_secret_key"
                          label="Google Recaptcha Secret Key"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.maps"
                          label="Harita Kodu"
                          hint="(Google Maps, Bing veya Yahoo!) Kodları bu alana yapıştırabilirsiniz."
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings._dosyaOnEki"
                          label="Dosya Ön Eki"
                          hint="Örnek: mavera_bilisim_ (Dikkat! arada boşluk ve türkçe karakter bulunmamalıdır)"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.bank_order_prefix"
                          label="Banka Sipariş No Ön Eki"
                          hint="Örnek: MAVERA_ (Dikkat! arada boşluk ve türkçe karakter bulunmamalıdır)"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.api_code"
                          label="Api Şifresi"
                          type="password"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.puan_kampanyasi_adi"
                          label="Puan Kampanyası Etiketi"
                          hint="Örneğin: EKSUN Puan"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row align="center">
                      <v-col
                        cols="12"
                        sm="7"
                      >
                        <v-text-field
                          v-model="settings.sender_email"
                          label="Gönderen E-Mail Adresi"
                          hint="Sitenizden ziyaretçilere / kullanıcılara gönderen mail adresi olarak gözükecektir."
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                      >
                        <v-text-field
                          v-model="settings.smtp_log_level"
                          label="Level"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="2"
                      >
                        <v-btn
                          block
                          depressed
                          color="nightDark"
                        >
                          Test
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        {{ settings.smtp_durum }}
                        <v-checkbox
                          v-model="settings.smtp_durum"
                          color="secondary"
                          label=" SMTP ile mail gönder"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row v-if="settings.smtp_durum">
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="settings.smtp_server"
                          label="SMTP Sunucusu"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="settings.smtp_secure_type"
                          label="SMTP Güvenlik Tipi"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="settings.smtp_port"
                          label="SMTP Port"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.smtp_username"
                          label="SMTP Kullanıcı Adı"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.smtp_pass"
                          type="password"
                          label="SMTP Şifre"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.siparis_email_adresi"
                          label="Sipariş E-Mail Adresleri"
                          hint="Sipariş maillerinin gönderilecek e-mail adresleri"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.iletisim_email_adresi"
                          label="İletişim E-Mail Adresleri"
                          hint="İletişim maillerinin gönderilecek e-mail adresleri"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.ik_email_adresi"
                          label="IK E-Mail Adresleri"
                          hint="İnsan kaynakları formu maillerinin gönderilecek e-mail adresleri"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.tahsilat_email_adresi"
                          label="Tahsilat E-Mail Adresleri (Başarılı)"
                          hint="Tahsilatların e-mail'leri hangi adreslere gönderilecek?"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.failed_payment_mail_rcv"
                          label="Tahsilat E-Mail Adresleri (Başarısız)"
                          hint="Tahsilatların e-mail'leri hangi adreslere gönderilecek?"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="settings.async_email"
                          color="secondary"
                          label="Asenkron E-Mail Gönderimi"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.canias_client"
                          label="Client"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.canias_langu"
                          label="Langu"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.canias_db"
                          label="DB"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.canias_dbserv"
                          label="DBServ"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.canias_appserv"
                          label="Appserv"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.erp_saldept"
                          label="ERP Satış Departman Kodu
"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.canias_user"
                          label="User
"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.canias_pass"
                          type="password"
                          label="Pass
"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.canias_wsdlurl"
                          label="WSDLURL"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.canias_version"
                          label="CANIAS Version
"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.default_sal_doctype"
                          label="SALDOCTYPE
"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.default_cust_no"
                          label="Müşteri No (B2C)"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.default_cust_no_b2b"
                          label="Müşteri No (B2B)"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.pos_ok_url"
                          label="Sanal POS Başarılı URL"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.pos_fail_url"
                          label="Sanal POS Başarısız URL"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.havale_deep_disc_rate"
                          label="Havale İndirimi (%)"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="settings.cc_deep_disc_rate"
                          label="KK İndirimi (%)"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <v-card-actions>
            <v-btn
              depressed
              x-large
              block
              rounded
              color="secondary"
              @click="save"
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from '@/store'
import { mdiAccountCircle, mdiAt, mdiCalendar, mdiCog, mdiLock, mdiPhone, mdiShieldAccount, mdiUpdate } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  setup() {
    const settings = ref({})
    const loading = ref(true)
    const pageLoading = ref(true)
    const tab = ref(0)
    const items = ref(['Genel', 'Site Tanımları', 'E-Posta Ayarları', 'CANIAS Ayarları', 'Diğer Ayarlar'])
    onMounted(() => {
      const method = 'getSettingParams'

      store
        .dispatch('postMethod', { method })
        .then(response => {
          if (response.error === 0) {
            const result = {}
            response.response.forEach(item => {
              if (item.setval === '0' && item.setkey != 'smtp_log_level' && item.setkey != 'cc_deep_disc_rate') {
                result[item.setkey] = false
              } else if (item.setval === '1') {
                result[item.setkey] = true
              } else {
                result[item.setkey] = item.setval
              }
            })
            settings.value = result
          }
        })
        .catch(error => {
          if (error.response.error !== 0) {
            settings.value = {}
          }
        })
        .finally(() => {
          pageLoading.value = false
        })
    })

    const save = () => {
      const result = {}

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(settings.value)) {
        if (value === false) {
          result[key] = '0'
        } else if (value === true) {
          result[key] = '1'
        } else {
          result[key] = value
        }
      }

      const data = result
      const id = ''
      const search = ['baslik', 'kisa_aciklama']
      const method = 'saveSettingParams'

      store
        .dispatch('addUpdateMethod', {
          data,
          search,
          method,
          id,
        })
        .then(response => {
          if (response.error === 0) {
            Vue.swal({
              title: 'Değişiklikler Kaydedildi',
              text: 'İşlem başarılı',
              icon: 'success',
              timer: 1500,
              background: '#56CA00',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })
        .catch(e => {
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    // ui

    return {
      loading,
      pageLoading,
      tab,
      items,
      settings,
      save,
      icons: {
        mdiShieldAccount,
        mdiAccountCircle,
        mdiLock,
        mdiPhone,
        mdiAt,
        mdiCalendar,
        mdiUpdate,
        mdiCog,
      },
    }
  },
}
</script>
